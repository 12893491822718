import React, { Suspense, lazy } from 'react';
import Header from '../common/header';
import Footer from '../common/footer';

function Kerala() {
  return (
    <React.Fragment>
      <Header parent="Destinations"/>
        <main class="content">
          <div class="fullwidth-block">
            <div class="container">
              <div class="row">
                <div class="col-md-12 wow fadeInLeft">
                  <div class="" id="Munnar">
                    <h2 class="section-title">Kerala – God’s Own Country</h2>
                    <p>When National Geographic termed Kerala among the worlds ten paradises, the world suddenly sat up to take a relook at this pristine, green and blue state of southern India. With its white sand beaches , sacred temples, resplendent festivals, lush hills and placid backwaters, Kerala is especially known for its ecotourism initiatives. The term “God’s Own Country” might have started out as marketing tool, but it does sum up this state for what it is.
 </p>
                    <p> Located on Malabar Coast, it has unique culture and traditions coupled with varied demographics. From hill stations to beaches and wildlife sanctuaries to backwaters, Kerala is fast emerging as one of the tourism hotspots of the world.
</p>
                    <p> Referred to as ‘Keralam’ by natives , the name literally translates to ‘the land of coconut’ (Kera in Malayalam means ‘Coconut’ and alam stands for ‘land’).
</p>
                    <p> Kerala finds mention in the ancient Puranas, according to which it was created after Parasuram threw his battle axe into the sea. A thriving centre of international trade since 3000 BC, it played host to travelers and traders including Greeks, Romans, Arabs, Chinese, Portuguese, Dutch, French, and the British.
</p>
                    <p> Commerce being major asset, this so – called Spice Coast of India now also boasts of the highest literacy rate in India and literacy among women being the highest in Asia. Ancient, mystical and modern, Kerala is the perfect land, if there ever was one.
</p>
                   
                  </div>

                </div>

                
                
              </div>

            </div>

          </div>

          
        </main>

      <Footer/>
    </React.Fragment>
  );
}

export default Kerala;
