import React from 'react';
import Header from '../common/header';
import Footer from '../common/footer';
import DatService from '../services/dataService';

function Destinations() {
	
  return (
    <React.Fragment>
      <Header parent="Destinations"/>
        <main class="content">
          <div class="fullwidth-block">
            <div class="container">
              <div class="row">
                <div class="col-md-8 wow fadeInLeft">
                  <div class="" id="Munnar">
                    <h2 class="section-title">Munnar - The Sparkling wonder</h2>
                    <p>Munnar, the most beautiful hillstation of Kerala also known as the Kashmir of South India laying at a height of 5200 ft above the sea level is a heaven for travellers. This spectacular natural wonder of  Kerala attracts thousands of visitors every year to it. The fantastic town of Munnar turned to a hill station by the British as their summer spot is well known for it's calm, pleasant climate and pristine good looks.</p> 

                    <p>The celestial beauty of Munnar is its rich biodiversity.The cool climate , extensive tea plantations, the waterfalls in and around , the dams , the wildlife sanctuaries makes Munnar a classic among the long list of hillstations all around the globe. </p>

                    <p>The  name Munnar came from the word " Moonu Aaru" which meant , the confluence of three rivers Kundala, Nallathani and Madhirapuzha. This glorious hill station lets you to open up into the nature and to get lost in the cool, misty, serene nature. Munnar gives a unique package of green everywhere. Munnar has  Chinnar Wildlife sanctuary,Kurinjimala sanctuary, Eravikulam National Park, and Pampadum Shola National park blanketing it.</p>
                    <p>Munnar is the home to the endangered species The Nilgiri Tahr ( Nilgiritragus hylocrius) which can only be found in India is protected in the Eravikulam National Park.</p>
                    <p>Another wonder, that is in the enchanting mountains of Munnar is the unique marvelous Shrub Neelakurinji ( strobilanthes Kunthiana) . Purplish blue flowers of Neelakurinji blossoms only once in 12 years is found in the Shola forests of the Nilgiri Hills in western ghats. The scene of Munnar hills bathed in the shade of blue once in every 12 years is truly a spectacular vision.</p>
                    <p>Munnar may be addressed as a Natural wonder blessed with a bunch of stunning places to visit like Mattupetty Dam, where you can spot the Asiatic elephants is about 13 km from the Hill station. The artificially formed Kundala Lake located 20mkm from Munnar ,Tea Museum , Attukal waterfalls , Elephant village Anakulam , Rajamala wildlife sanctuary, Mattupetty , Marayur, Anamudi peak , Eravikulam National Park,Top station are quite a few place to mention in the Natural Wonder ,Munnar .</p>
                    <p>Munnar is well connected to Airports and railway stations and major cities of India by the state and national highways. Munnar is 110 km away from the nearest airport in Kerala ,Cochin International Airport and 140 KM away from Ernakulam , which is the nearest railway station</p>
                    <p>Munnar always welcomes the visitors , but the ideal time is in the months of October - November and January- May.</p>
                    <p>Misty hills ,the green lush tea plantations, the winding roads, the flora and fauna , the stunning waterfalls, marvellous lakes ,the  dams and the  wildlife of Munnar is going to make you a wooer of the hill town.</p>
                  </div>

                  <div class="" id="Alappuzha">
                    <h2 class="section-title">Alappuzha</h2>
                    <p>Alappuzha or Alleppey,the 'Venetian capital of Kerala, a small district  with colossal natural beauty. This district is mostly in the costal plains . People are fascinated  to visit this place to explore  the unique  beauty because of its alluring geographical  features. </p> 

                    <p>The charming canals ,dashing backwaters,lushy beaches,greenery- fringed paddy fields, enthralling lakes and lagoons which strikes the  visitors.What adds to the history  ?It's the Portuguese  contribution of the 17th century  built lighthouse which adds more beauty to the city.</p>

                    <p>The core  of Alleppey is cruise , more than thousand houseboats are gliding through the  lakes,which  gives a delightful  experience  by snatching good vibes .</p>
                    <p>Alleppey is known  as a  paradise to explore  diverse spots .The backwaters of Kuttanad-waterlogged stretches about 110,000 hectares,Marari Beach,Vemband lake, Alleppy beaches,Pathiramanal,Krishnapuram Palace,Arthunkal Church ,Aambalapuzha temple,Mannarashala Temple are the spotlight to visit </p>
                    <p>The shimmering lakes and diverse landscape indulges the visitors to relax amidst in a peaceful environment  at Alleppey.</p>
                  </div>

                  <div class="" id="Kovalam">
                    <h2 class="section-title">Kovalam</h2>
                    <p>The splendiferous Kovalam Beach located 17 kilometres from the city centre of Trivandrum is a sort of place one would ever wishe to be. This splendid beach with clear, Shallow water and light black coloured sand makes it ranked top among the beaches of the whole world as well as in the minds of tourists too.</p>
                    <p>The pristine beach has got into the charts when it was exalted as the spot for beach resort of the Travancore royal family . It is located in the serene village Kovalam with green lush coconut grooves which add beauty to the beach. The shallow water and the low tidal waves are the gifts that make Kovalam ideal and most sought after among the beach lovers all around the world.</p> 

                    <p>In its long stretching 17 KM, coastline  Kovalam has three major beaches separated by projected rocks in the sea. </p>

                    <p>The southmost lighthouse Beach is probably the most popular 
                    among the three. The majestic 118 feet height Lighthouse dressed in red and white can give you a  magnificent gaze to the long stretching beach and to the beauty of the blue waters. The Lighthouse , from which the beach got its name , adds up charm by its beams of light in the night. The waves of lighthouse beach are perfect for surfing .
                    The Hawah beach, also known as Eve's beach is a paradise for the lovers of the beach. The calm bay of blue waters and a high elevated rocks in the beach elevates its beauty . 
                    Samundra beach , can give you the magnificent view of the local fisherfolk who go into the sea and to their business . This beach, not so popular among the tourists however can give the picture of the life of local people .
                    The highheld coconut grooves on the side of the beaches are alluring .Beach has a long line of shops that offer all kinds of goods can give a shopping experience . Apart from the natural beauty Kovalam is a hub of various water sports like surfing, swimming and boating .  
                    In Kovalam you can rejuvenate the mind and body with the Ayurvedic massages and various treatment facilities are offered by the various resorts  and centres ..</p>
                  </div>

                  <div class="" id="Kumarakom">
                    <h2 class="section-title">Kumarakom</h2>
                    <p>Kumarakom,a verdure village in the backdrop of vembanad lake,the largest lake in Kerala.The serne beauty of Kumarakom entice tourists from far and near .The  splendid views of the backwater gives a ray of euphoria.</p>
                    <p>Kumarakom is near to reach out  tourist destination which is located near to the city of Kottayam,in Kerala,India.The temperate climate of this place makes it more congenial .Moreover, Kumarakom has been declared as a Special Tourism Zone by the Kerala State government .
                    The light spot of Kumarakom is the bird sanctuary,which resides near to the picturesque backwater  of Kerala.The Kumarakom Bird Sanctuary , stretches over  14 hectares is beloved by avian species of migratory birds . It's treasured for the glorious migratory birds ,from the Himalaya to Siberia. Boat cruise is available in the sanctuary ,which is a window to relish on the sparkling backwaters.</p> 

                    <p>The optimal time to visit the sanctuary is between November and February ,where flocks of migratory birds can be peeped at , and from June to August ,we can have a glimpse on resident birds also.
</p>

                    <p>The charmness of Kumarakom encompasses with Aruvikkuzhi Waterfall, Kumarakom Beach,Vembanad Lake,Bay Island Museum,Pathiramanal Island, and Kumarakom craft Museum.
                    To reach Kumarakom, the Cochin International airport is nearest ,which is located 85 km away from Kumarakom.Kottayam railway station is nearest to Kumarakom ,that is located at a distance about 16 km.Buses are accessible from Kottayam KSRTC bus stand to reach Kumarakom.</p>
                  </div>

                  <div class="" id="Thekkady">
                    <h2 class="section-title">Thekkady</h2>
                    <p>Thekkady ,a verdant place located at Idukki district ,in the border of Kerala and Tamilnadu.</p>
                    <p>The miraculous beauty of Thekkady allure people to roam around here. It is far and wide known for the lushy vegetation and covered with evergreen and semi evergreen forests of Western Ghats. Thus it is placed at a altitude of 700 m above the sea level.The ethereal ambience of Thekkady is wonderful to capture good moments  which is heaven for a nature lover .</p> 

                    <p>The Periyar Wildlife Sanctuary is spread across 777km (30sqm)  and surrounded with the artificial lake known as Periyar lake.In 1895 ,the  construction of Mullaperiyar Dam  across the Periyar river led for the creation of  artificial Periyar lake. Boat cruise is avail which gives an idyllic view of the sanctuary. The presence of the erotic ,rare and endangered species adds more charm to this place . Trekking ,Jeep Safari is also accessible to have sighting of the lavish dark wood .
                    </p>

                    <p>The other places to explore in Tekkady are Ottakathalamedu,Murikady,Chellar Kovil,Anakara,Mangala Devi Temple and so on.Winter (October-February) is the best time to visit Thekkady .</p>
                    <p>It is easy to reach Thekkady by stepping down from the nearest airports at Cochin and Maduari. Madurai and Kochi Railway Stations  are the nearest . Moreover,buses are easily available from every where.</p>
                  </div>

                  <div class="" id="Kozhikode">
                    <h2 class="section-title">Kozhikode</h2>
                    <p>Kozhikode, the former capital of Zamorin kings also known as the city of spices is one of the most visited places in Kerala. Kozhikode known earlier as Calicut is an ever romantic destination in the travel map. Kozhikode is a rich city with historical and cultural heritage, it is also home for several traditional arts including the Kerala martial art Kalaripayattu. The natural beauty of the city can enthrall you like none other. Legacy of the traditional martial art, the culinary magic and the calm, serene and vast beaches, the aroma of spices, the sweetness in the streets and of course the wonderful people, and a rich culture, heritage and tradition sugarcoated with modernity is making the city a medley of sweetness and fond memories.</p>
                    <p>Away from the ruckus of the city, Kappad beach stands an ideal beach destination for relaxation and refreshing. Located 16 km away from the city is the place where the Portuguese navigator Vasco da Gama landed in 1498 from Europe; there is a park and a monument.    Dotted with captivating rock formations on all sides, the beach landscape includes several coconut groves and long lines of silvery sands. An ancient temple on a hillock, facing the sea, is an added attraction. Feel the gentle caring of the sea breeze in Kappad beach which will melt all the heaviness of the mind. This beautiful beach is standing out for its beauty as well as the cleanliness.  The beauty of Kerala beaches is unmatched and the Kozhikode beach is another enchanting gift of Kozhikode. This historical beach witnessed several historical events, the sight of the placid waters of the sea and the relatively clean beach is a big attraction for tourists. If you come to Kozhikode it is important to go and pay a visit to Beypore, which is known for its ancient shipbuilding industry. This is the place where you can find the construction of the Uru or wooden dhow, the trading vessel popular during the medieval periods and still used by for trade, the sea shore is attractive with a sea port and a landing for fishing boats.</p>
                    <p>Apart from the beaches, Kozhikode is gifted by serene natural beauty. Elathur, a beautiful riverside village with an estuary is a 27-acre sacred grove in a swamp at Edakkara near Elathur. You can see monkeys, peacocks, wild boars, porcupines, wild hens, different kinds of butterflies and many species of orchids here. There is a species of herb here which has leaves in the shape of butterflies. Mananchira pond and Gardens with many beautiful statues gives a calm wake around. The important location everyone comes to spot in the city is the bustling local market, S.M. Street or Sweet Meat or locally called Mithai Theruvu is a buzzing lane north of Mananchira Square. It is believed that this street is named after the locally popular sweet called halva; it is the home of word famous Kozhikode halva. A walk through the street seeing the bright colours and tasting the aromatic sweets is a splendid experience one cannot let away once came to Kozhikode. The culinary excellence and varieties of Kozhikode is a must try, the city offers different shops and places which can deliver the traditional cuisine.</p>
                    <p>The city is connected well to all the nearby tourism destinations. The nearest airport is Karipur International Airport just 27 km away and trains are available to all important cities of India.  A well connected centre of business from all part of Kerala as well as neighboring states by road , Kozhikode is kind of place which will come at the top of the priority list.</p>

                    </div>

                    <div class="" id="Athirappaly">
                    <h2 class="section-title">Athirappaly</h2>
                   <p>The unique serene waterfalls situated 1000 ft above the sea level falls from a height of 80 ft is the biggest waterfall of Kerala is the main attraction of the place . Located 60 km away from the city of Thrissur, this beautiful country side at the entrance to the Sholayar ranges of the Western Ghats, Athirappalli is a scenic combination of forests and little stream. The main attraction Athirapally waterfall is in the Chalakudy River. This place rich in biodiversity Athirappally also houses the Vazhachal and the Charpa waterfalls. The ecosystem of this place is considered to be one of its kind in the state of Kerala is melodious with its rain forests and waterfalls. Many endangered and endemic species of flora and fauna are found in the forests of the Athirapilly-Vazhachal area. This area is the only place in the Western Ghats where four endangered hornbill species are seen. The Western Ghats is one of the most important biodiversity hot spot in the world.</p>
                   <p>Athirapally is an important area protecting the avian species, endangered species such as hornbills are found in this area. The main attraction the place is waterfalls of Athirapally. The 80 ft high waterfall which stands in the serene forest with its mystic beauty is unique experience. Way to the waterfalls passes through a landscape of winding roads, small villages and lush green trees. The greenery around the waterfalls spreads a halo of serenity around the waterfalls. The forest is habitat of different species like the Indian grey hornbill, Malabar pied and grey hornbill, lion-tailed macaque, Asiatic elephant, tiger, leopard, bison, sambar, etc Daily jungle safari trips are organized by Thrissur District Tourism Promotion Council with Athirappilly Destination Management Council from Chalakudy to Malakkappara is the another attraction we have in Athirapally. Nearly 5 km away is another family favourite, Vazhachal Waterfalls. These waterfalls became famous for not just their view but the endemic species found in the surrounding dense forests. This place is also famous for being spotted by the presence of endangered species of Hornbills. A unique trek to the nearby tribal village experiencing the treasures and gifts of nature is going to be great treasure of memory. harpa Falls, Anakkayam, Sholayar Dam, Valparai and Malayattur Wildlife Sanctuary near Athirappilly are worth exploring. There are adventure activities like river rafting, trekking, etc. Though the waterfalls of Athirappilly active all the time its charm can be best experienced during the time of monsoon.</p>
                   <p>The nearest railway junction to Athirapally is Chalakudy, which is 32 km away. Cochin Airport is the nearest airport to Athirapally which is just a distance of 40 km. Kochi and Thrissur are two major railways junctions nearby. One can also relay on public transport and private vehicle to reach Athirapally</p>
                    </div>

                    <div class="" id="Kochi">
                    <h2 class="section-title">Kochi</h2>
                    <p>Kochi the metropolitan city of Kerala called the "Queen of the Arabian Sea", Kochi was an important spice trading centre on the west coast of India from centuries before, and maintained a trade network with Arabs, Dutch, Phoenicians, Portuguese, and Chinese more than 600 years ago. This major port city which is the part of Ernakulum district of Kerala is a hot spot for travelers right from the beginning. This trade relation helped Kochi to prosper and to become the gateway to old India. It was from Kochi that the colonization of India started. Portugal was first to establish its base in Kochi in 1500s, followed by the Dutch and English. The British managed to establish their influence over Kochi, limiting their direct administration to a small enclave of Fort Kochi and British Ernakulum with their capital at Bolgatty Island. However, the rest of the Kochi was administered by Kochi Maharajas from their capital at Thripunithura.</p>
                    <p>Kochi, the commercial capital of Kerala is also the place in Kerala with highest number of National and International travelers. Kochi was traditionally a mélange of various Indian and international communities. Syrian Christians started the first wave of immigration, followed by Jews between the 7th and 10th centuries. Arab merchants also made a strong settlement in Kochi, apart from these foreign settlers various communities across India has made Kochi as their home. This  cultural confluence has made the Queen of the Arabian Sea more attractive.  Later, at the beginning of the colonial era, the Portuguese, Dutch, French, and British all made their settlements in Kochi. The influence of all these cultural mixing can be seen in the cultural and social life of Kochi as well as in the architecture and the culinary smack. </p>
                    <p>Despite being a modern city we can find the beauty and modesty of rural life in Kochi especially in villages and rural areas. From ‘Paradesi Synagogue’ to the palaces which brings the glimpses of cultural diversity and heritage, from a Metro trip in the busy city to the canoe ride in the backwaters of Kumbalangi, Kochi is full of  all sort of Emerald that one look for.
                    Willington Island — this man-made island made from sand dredged from the backwaters to deepen the Kochi Port. Named after Lord Willington, then-the reigning Viceroy of India, it houses the Kochi Port, Southern Naval Command Headquarters of Indian Navy and many five star hotels. A boat ride to the Island is something always to be remembered of. </p>
                    <p>Mattancherry and Fort Kochi (Old Kochi) — primarily a tourist enclave. Fort Kochi forms the upper part of the Peninsula with neatly arranged colonial buildings, narrow well-paved roads, Anglo-Dutch influenced structures and large antique shops. Mattancherry is primarily a spice trading city.A walk through the street can give you a Peek into the city’s thriving history. The Jew Street and the Paradeshi Synagogue, which is 450 years old is the oldest Synagogue in India. Jew Town in Mattancherry was the place where the Jewish people had settled. After Israel was established as a nation, most of the Jew families had migrated to Israel. Though, there are still a few houses/ shops in Jew town where you can experience the authentic Jewish culture. Another sight one cannot miss in  Fort Kochi is the beach, popular for Chinese Fishing Nets. Mornings at the Fort Kochi beach are busy when the fishermen open their Chinese Nets hoping for high tides. In the evenings, it gets even busier because of the mesmerising and magical sunsets at the Fort Kochi Beach. A brisk walk through the streets seeing the artistic murals on the walls of Fort Kochi is going to give you a nice impression.</p>
                    <p>Cherai Beach in Vypin Island the biggest blessing for the travelers from the coastline is the beaches. Located about 30 km from the heart of Kochi is the palm-fringed Cherai Beach, with a 15 km shoreline. One can swim in the calm waters of cherai. It also offers speedboat, water-scooter, and canoe ride options.. The sunsets there are priceless and occasionally you can spot Dolphins too. Kumbalangi, a virgin tourist spot 14 km from Kochi’s city centre is going to be a priceless experience; a gentle walk through the serene village will make you understand why Kerala is known as Gods Own country   . This tiny fishing hamlet facing the Kochi backwaters is designated India’s first ecotourism village and the first rural tourism village of the state. Enjoy authentic, home-cooked Malayalee cuisine, and watch the fishermen in action as they ride into the backwaters on their canoes. Kochi always holds unique places to explore. The place like Dutch Cemetery in Fort Kochi welcomes you to the historical past of the city. Chendamangalam Palace Fort, the once seat of the legendary Paliath Achan, the Prime Minister of Kochi Kingdom, The Pallipuram Fort , Santacruz Basilica are few among the place to visit in Kochi. Apart from these places Kochi is a hub of religious and cultural activities too. 
                    The Cochin International Airport connects Kochi to all parts of the world. Being the commercial capital and the important destination on the map, Kochi, is well connected to all important cities of India through Road and Railway.</p>
                    </div>
                    <div class="" id="Vagamon">
                    <h2 class="section-title">Vagamon - The Misty Mystic </h2>
                    <p>Vagamon, calm yet wondrous hill station of Kerala situated 1200 m above the sea level welcomes you to the green serene hillside. Being one of the most loved places in Kerala , the beauty of God's own country can be felt in this green lush hill blanketed in mist covered tea and coffee plantations. </p>
                    <p>The Misty hills of Vagamon can carry away all the stress of the busy side of the world and make you feel relaxed with the mesmerising natural beauty. The lofty Pune forest covering a vast area of the sloppy hills of Vagamon is the prominent place that welcomes you to the mystic beauty of the enchanting hills. The murmuring Pine forest can take you into a meditating confluence with nature. The cool breeze coming embracing the Pine trees will leave you refreshed.</p>
                    <p>The Tea Gardens of Vagamon can offer you a soothing wind and will tranquilize you with natural beauty .  The vast Tea gardens with bright greenery showering in cool mist is a visual retreat.</p>
                    <p>Vagamon is a place blessed with natural as well as cultural harmony. The three hills, Thangalpara, Muruganmala and Kurisumala adds up to the mystical beauty of Vagamon. Kurisumala Hill will.offer you a trek of adventurous mode where reaching atop can give you one of the beautiful sight you can have of Vagamon with a feel of spiritual bliss. The beautiful small church and the vibe of the hilltop is going to be a precious feel. Kurisumala offers you a visit into the Kurisumala Dairy run by monastery named Kurisumala Ashram.</p>
                    <p>The mesmerising Meadows of Vagamon is the another gift of nature that keeps you bind. The attractive meadows of Vagamon is a calm place with the misty mountain breeze and tranquility . These velvet lawns can take you into the cooling touch of virigin nature. A lake that you can find in the valley of a mountain is an another not much explored spot offers the happiness of being one with nature.</p>
                    <p>We have the places like Illikal Kallu, Parunthumpara and Marmala Waterfalls near to this hilly beauty which offers the marvels of calmness and serenity . This enchanting, tender hill station is a magical place where you can scribe your valuable moments . This shy belle of nature can be easily reached from railway station of Kottayam at a distance of 40.kms . The nearest airport is Cochin International Airport which is approximately 120 Km away. This impressive scienic  beauty spot is one of the most attractive place to visit in India.</p>
                    </div>
                    <div class="" id="Bekal">
                    <h2 class="section-title">Bekal</h2>
                    <p>The 300 years old historical monument stands at the confluence of Arabian Sea and the northern most district of Kerala, Kasargod spreads over 40 acres. This massive edifice which is a silent witness to several historical events including wars is among the largest Forts in Kerala and has been perfectly taken cared of.</p>
                    <p>This long standing Fort runs into the sea with almost three quarters of its exterior covered by the reigning Arabian Sea. This Fort of northern Kerala is believed to be built by Kolathiri Rajas of Kerala and later it was captured by Shivappa Nayaka. It was conquered by Hyder Ali and Bekal fell into the hands of Mysore Kings. Tipu Sultan used Bekal Fort as an military station . The archaeological excavation conducted at Bekal Fort unearthed the coins and other artifacts of Mysore Sulthans. After the martyrdom of Tipu Sulthan the Fort came under the British East India Company . British used the Fort for military and administrative purposes. </p>
                    <p>The architecture of the Bekal Fort is the key attraction for the visitors. From the architecture of the Fort it is evident that it is used for military actions, as it is not having any palaces or manisons inside the Fort. The Zig-zag entrance and sourrounding trenches shows the defensive strategy used in the building of the Fort. Special holes were designed in the walls for artillery to defend any possible Naval attack. The Fort is further featured by a water tank, Magazine to keep ammunition and an observation tower built by Tipu Sulthan , which offers magnificent view of the coastline and nearby town.</p>
                    <p>Bekal Fort in its new attire with all its legacy welcomes the visitors all around the globe. Public Works Department has maintained a Travellers Bungalow inside the Fort for the visitors. The charming view of the Bekal seashore and it's unparalleled natural beauty adds up to the majesty of the Fort . A tented camping accomodation is also provided at the coconut plantation at the beach area. </p>
                    <p>This eco friendly maintained Fort area was declared into special tourism area in1992 and formed in Bekal Tourism Development Corporation in 1995. Bekal is now establishing its place in international tourism destinations of cultural and historical importance. Local roads connect to Mangalore in the north and Calicut in the south. The nearest railway station is Bekal Fort Railway Station. Nearest airports are  at Mangalore, Kannur and Calicut.</p>
                    </div>

                </div>

                
                <div class="col-md-3 col-md-push-1 wow fadeInRight">
                  <h2 class="section-title">Favourites</h2>
                  <ul class="list-arrow alt">
                    <li><a href="destinations/#Munnar">Munnar - The Sparkling wonder</a></li>
                    <li><a href="destinations/#Alapuzha">Alapuzha</a></li>
                    <li><a href="destinations/#Kovalam">Kovalam</a></li>
                    <li><a href="destinations/#Kumarakom">Kumarakom</a></li>
                    <li><a href="destinations/#Thekkady">Thekkady</a></li>
                    <li><a href="destinations/#Kozhikode">Kozhikode</a></li>
                    <li><a href="destinations/#Athirappaly">Athirappaly</a></li>
                    <li><a href="destinations/#Kochi">Kochi</a></li>
                    <li><a href="destinations/#Vagamon">Vagamon</a></li>
                    <li><a href="destinations/#Bekal">Bekal</a></li>
                  </ul>
                </div>
              </div>

            </div>

          </div>

          
        </main>

      <Footer/>
    </React.Fragment>
  );
}

export default Destinations;
