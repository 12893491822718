import React, { Suspense, lazy } from 'react';
import Header from '../common/header';
import Footer from '../common/footer';

function BackWaters() {
  return (
    <React.Fragment>
      <Header parent="Destinations"/>
        <main class="content">
          <div class="fullwidth-block">
            <div class="container">
              <div class="row">
                <div class="col-md-12 wow fadeInLeft">
                  <div class="" id="Munnar">
                    <h2 class="section-title">On the Backwaters</h2>
                    <p>The backwaters of Kerala’s Alappuzha and Kumarakom are the USP of Kerala, and the Kettuvallam perhaps the most famous Kerala’s icons. In this unique fresh-and-salt water eco-system, life in Kuttanad is lived at a deliciously slow pace. The Kettuvallom glides across the canals, stopping at regular intervals for you to discover another amazing spectacles on this incredible journey…</p> 

                    <p>Kerala’s glorus Kuttanad region, on both banks of Vembanad Lake, has the fabled backwaters, a rich culture that informs people’s lives  and not just memories , a tradition of architecture that still alive. You can experience the region majestically on one of those magnificent Kettuvallams that have become kerala’s defining image. Comfortable ambling vessels, they even come with air conditioning. You get a crew of cook and a bearer along with driver. From single bedroom to four and even five bedroom behemoths, with living rooms, en-suite bathrooms and equipped kitchens, you get the lot.  </p>

                    <p>TWhat are the backwaters, various explanations for what actually makes a body of water a backwater. The most plausible was that they are inter connected fresh water rivers and canals that feed towards the sea, through a couple of larger lakes , that seasonally get charged with saline water, when the sea backs up. Hence ‘backwater’. The sea can do this , because Kerala’s short lived rivers, which orginate in Western ghats relatively close to the sea, just don’t have the strength of longer rivers to hold the sea back</p>

                    <p>An old system of locks and sluices keeps the salt from the fresh water canals, and obviously the people of Kuttanad have the system down to a fine art, because rice cultivation flourishes on the low lying islands that dot the backwaters. These islands are green, an entire palette of green , from the deep green of the coconut fronds that fringe the islands to the lighter green of the rice shoots themselves. Even the water of backwaters , reflecting all the colour, turns green. In some of the narrow canal, the trees meet overhead, and a cool shade pervades the scene.</p>

                    <p>The houses are oriented towards the ‘kayals’ since those are roads of the backwater economy. And the life of people that live along these waters is open to view.
You can start your backwater tour from a number of places , including Allappey, Ernakulam, Kottayam, Kumarakom and Kollam.
</p>

                    <p>Vembanad Lake
Heading north towards Muhamma and deep into Lake Vembanad, a freshwater lake into which the river Pamba and its branches drain. This can be disconcerting, as when you are in the middle of the lake, and you look down, you notice that the bottom is only about 6 feet deep. To get there, the backwaters trail can take in an excursions to various blocks of the backwaters that the lake including R and QST blocks. These fall in adjoining Kottayam district, where Kumarakom is located. The resorts of the Kumarakom are clearly visible across the water. The many islands that dot the backwaters here crammed with rice fields, and there is one that has coconut and other plantations. But mostly there is just rice. A walk through the paddy is easily arranged</p>
                    <p>Alappuzha- ‘Venice of the East’
Alappuzha retains some of the charm history of trade with the world has bequeathed it. However , its everlasting attraction is its location, for Alappuzha , which occupies a pretty thin strip of land between the backwaters and sea, is the capital of backwater country. Many of the famous houseboats are based there. Houseboat cruising follows are several stops along the backwaters-resorts, temples and villages where snake boats are made.
Kumarakom-‘Queen of Vembanad
When former prime minister Atal Behari Vajpayee chose to holiday here in 2000, he surprised the nation with his musings from Kumarakom , saying time was ripe  ‘to resolve problems of the past’ and ‘move towards a better future’. Kumarakom is the banks of the Vembanad lake, has that effect on people. Of course , it doesn’t hurt have a special way with words, but Kumarakom does provides oodles of inspiration.
This placid resort village of the Kuttanad backwaters drags the muse out of even the most poetically challenged. So pack your quill and head for what are arguably the most beautiful acres along the kuttanad backwaters, to pal fringed canals, clumps of coir retting in water, a fishing boat returning with the days catch, a boat laden with pots and pans making the round to shoppers in waterfront houses…</p>
                  </div>

                </div>

                
                
              </div>

            </div>

          </div>

          
        </main>

      <Footer/>
    </React.Fragment>
  );
}

export default BackWaters;
