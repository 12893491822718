import React, { Suspense, lazy } from 'react';
import Header from '../common/header';
import Footer from '../common/footer';

function Ayurveda() {
  return (
    <React.Fragment>
      <Header parent="Destinations"/>
        <main class="content">
          <div class="fullwidth-block">
            <div class="container">
              <div class="row">
                <div class="col-md-12 wow fadeInLeft">
                  <div class="" id="Munnar">
                    <h2 class="section-title">Ayurveda – Art of Living</h2>
                    <p>Think Ayurveda and you think of Kerala. Ayurveda is a science that concerns itself with do’s and dont’s  for a happy and healthy life. Life that derives it essence from one of the worlds most ancient systems of medicine.
</p> 
<p>The story of Ayurveda, the science of Life, with its myriad beginnings , has no conclusion. Transcending time and narrator, this ancient lore, which fact and fantasy as well as science and conjecture are interwoven, is dyed into the very fabric and texture of Indian life. </p>




<p>The tradition of Ayurveda lies within the Vedic lore of ancient india. Through the orgins of Ayurveda are lost in obscurity and myth, the science of life is belived to have been discovered by the author of four vedas, Lord Brahma itself. Ayurveda also said to be book of wisdom gatherd over centuries by many great saints who codified into the healing science we know today. What we do know is that it is not a quick-fix solution, nor does it offer instant nirvana.
</p>
<p>To rejuvenate literally means ‘to make young again’. In ayurveda rejuvenation or Rasayana Chikitsa is the golden way to attain longevity. By restoring the spirit and vitality brings about a metamorphosis in the human body.    For the peculiarity of Ayurveda is that it looks at patient in entirety during treatment. Successful treatment involves through consultation with qualified doctors.
</p>
<p>Ayurveda is considered by many scholars to be the oldest healing science. In Sanskrit, Ayurveda means “The Science of Life.” Ayurvedic knowledge originated in India more than 5,000 years ago and is often called the “Mother of All Healing.” It stems from the ancient Vedic culture and was taught for many thousands of years in an oral tradition from accomplished masters to their disciples. Some of this knowledge was set to print a few thousand years ago, but much of it is inaccessible. The principles of many of the natural healing systems now familiar in the West have their roots in Ayurveda, including Homeopathy and Polarity Therapy.
</p>
<p>Ayurveda incorporates all forms of lifestyle in therapy. Thus yoga, aroma, meditation, gems,amulets, herbs, diet, astrology, color and surgery etc. are used in a comprehensive manner in treating patients. Treating important and sensitive spots on the body called Marmas is described in Ayurveda. Massages, exercises and yoga are recommended.
</p>
<p>The knowledge we have now is by three surviving texts of Charaka, Sushruta and Vaghbata.Charaka (1st century A.D.) wrote Charaka Samhita (samhita- meaning collection of verses written in Sanskrit). Sushruta (4th century A.D.) wrote his Samhita i.e Sushruta Samhita. Vaghbata (5th century A.D.) compiled the third set of major texts called Ashtanga Hridaya and Ashtanga Sangraha. Charaka’s School of Physicians and Sushruta’s School of Surgeons became the basis of Ayurveda and helped organize and systematically classify into branches of medicine and surgery.
</p>
<p>Ayurveda places great emphasis on prevention and encourages the maintenance of health through close attention to balance in one’s life, right thinking, diet, lifestyle and the use of herbs. Knowledge of Ayurveda enables one to understand how to create this balance of body, mind and consciousness according to one’s own individual constitution and how to make lifestyle changes to bring about and maintain this balance. Just as everyone has a unique fingerprint, each person has a particular pattern of energy—an individual combination of physical, mental and emotional characteristics—which comprises their own constitution. This constitution is determined at conception by a number of factors and remains the same throughout one’s life. Many factors, both internal and external, act upon us to disturb this balance and are reflected as a change in one’s constitution from the balanced state. Examples of these emotional and physical stresses include one’s emotional state, diet and food choices, seasons and weather, physical trauma, work and family relationships. Once these factors are understood, one can take appropriate actions to nullify or minimize their effects or eliminate the causes of imbalance and re-establish one’s original constitution. Balance is the natural order; imbalance is disorder. Health is order; disease is disorder. Within the body there is a constant interaction between order and disorder. When one understands the nature and structure of disorder, one can re-establish order.</p>

<p>Ayurveda is based on a belief that health and wellness are closely associated with the mind, body and spirit. Wellness is more than being free from illness, it is a dynamic process of change and growth. Wellness is an active, lifelong process of becoming aware of choices and making decisions toward a more balanced and fulfilling life.
</p>
                  </div>

                </div>

                
                
              </div>

            </div>

          </div>

          
        </main>

      <Footer/>
    </React.Fragment>
  );
}

export default Ayurveda;
