import React, { Suspense, lazy } from 'react';
import Header from '../common/header';
import Footer from '../common/footer';

function Arts() {
  return (
    <React.Fragment>
      <Header parent="Destinations"/>
        <main class="content">
          <div class="fullwidth-block">
            <div class="container">
              <div class="row">
                <div class="col-md-12 wow fadeInLeft">
                  <div class="" id="Munnar">
                    <h2 class="section-title">Performing Arts of Kerala</h2>
                    <p>Kathakali </p>
                    <p> Possibly the most celebrated of all dance forms of Kerala, Kathakali, in its present form evolved in the 17th century from other dances like Ramanattam and Krishnanattam. It is a form of dance drama, Characterised by dramatic costumes, colourful and elaborate make-up, detailed expressions and symmetrical movements. Most Kathakali performances retell stories from Hindu epics like Ramayana and Mahabharatha.</p>
                    <p> In its classical definition, Kathakali is composed of five elements – Natyam (expressions), nrithyam (enactment), nritham (dance), Geetha (vocal accompaniment) and vadyam (instrumental accompaniment). In recent years, Kathakali has developed new themes and found new audience not only in India, but also across the globe.</p>
                    <p>Mohiniyattam </p>
                    <p> One of the eight classical dance forms of India, Mohiniyattam, literarily  ‘dance of the seductress’ is, if legends are to be believed, the creation of Lord Vishnu Himself.History, However attests that it was created by the renowned dancer Vadivelu of Thanjavur in the early years of the 19th century.
Mohiniyattam involves elegant gentle movements and subtle facial expressions. It has been said that the dancer’s movements reflect swaying palm trees and the gently flowing rivers of Kerala.Performed solely by women, the dances are accompanied by classical singing, the lyrics of which are written in Manipravalam, a fusion of Sanskrit and Malayalam.
</p>
                    <p> Theyyam</p>
                    <p>It is believed that the legendary creator of Kerala introduced the Theyyam dance to the tribal communities of that era.The ritual dances passed on to the later generation are today performed by almost all classes of Hindu society. In Kerala the upper caste Brahmin, shares the same platform with the lower caste tribal while worshipping.
According to linguists, the word Theyyam is a corruption of devam , or god. Interestingly, most practitioners of theyyam consider the art itself to be a form of god. It is usually performed in groups   outside a temple, with the chief dancer portraying the mail deity enshrined. Over thousands of years that it has been practiced, Theyyam has developed regional and some times even seasonal styles. Sociologists are of the opinion that there are some 400 versions of this dance in North Malabar.
 </p>
                    <p>Kalarippayattu </p>
                    <p> Possibly the oldest form of martial art in the world, Kalaripayattu was practiced primarily by the Nair community of Kerala. Combining hand-to-hand combat with weapons, present forms and healing, Kalarippayattu can also be performed as a dance.
Taught in a Kalari, (School) under the strict supervision of a gurukkal, (teacher), it is said that one lifetime is not enough to master all the skills of Kalarippayattu!
</p>
                    <p> Oppana</p>
                    <p> A dance form of Malabar Muslim weddings, with the bride in centre, young girls and ladies of the family in colourful dresses, dance around and sing melodious wedding songs. The eldest lady of the family is given the privilege to begin the Oppana, which is then continued by others. Mappilappattu as it is called the lyrics of the wedding song in teasing and twitting mode, which intends to give a sight embarrassment to the would- be bride. Once confined to the four walls,  the dance today has come out as a drama or stage show.
</p>
                    <p>Ottamthullal </p>
                    <p> Often called the ‘poor man’s Kathakali’, it is performed by a single dancer who dances and recites the lyrics at the same time. This form of dance was an invention of Malayalam poet Kunchan Nambiar, who once fell asleep during a public presentation of Chakyar Koothu and was abused by the crowd and the leader. As a revenge to this humiliation, he invented the satirical one-act play which later became more popular than Chakyar Koothu itself. The 18th century art form did not develop merely as a dance, it talked about social immoralities and political issues which were predominant among the local people during those times. 
</p>
                  </div>

                </div>

                
                
              </div>

            </div>

          </div>

          
        </main>

      <Footer/>
    </React.Fragment>
  );
}

export default Arts;
