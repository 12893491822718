import React, { Suspense, lazy } from 'react';
import Header from '../common/header';
import Footer from '../common/footer';

function Theyyam() {
  return (
    <React.Fragment>
      <Header parent="Destinations"/>
        <main class="content">
          <div class="fullwidth-block">
            <div class="container">
              <div class="row">
                <div class="col-md-12 wow fadeInLeft">
                  <div class="" id="Munnar">
                    <h2 class="section-title">Theyyams -The dance of the folk god’s
</h2>
                    <p> “Theyyam is more about the participation of the local people than the actual performance … its about the abundance of bright colours, practically red. In one performance of Theyyam called Theechamundi , in which performer wears tender coconut leaves, watching the way he jumps into a huge bonfire is an experience which words cannot express”-  a tourist on seeing a Theyyam performance in the Malabar countryside. 
</p>
                    <p> Although confined to the northern hamlets of Malabar , Theyyam is the foremost folklore ritual art form of the state. The word ‘Theyyam’ refers to both the performance and performer, who ‘s said to attain supernatural powers during the performance.
</p>
                    <p> The roots of most Theyyams can be traced to the mythological incarnations of Lord Vishnu and shiva, about whom there are many legends. The method of worship in theyyam is highly ritualistic and closely linked to the worship of sakthi , Bhootha , Naga, ghost, ancestor , warrior and blood. A similar folklore ritual known as Bhootha is also observed in Karnataka.
</p>
                    <p> North Malabar’s unique, ancient, ritualistic folk art known as Theyyam, a corruption of the Malayalam word ‘daivam’ meaning god, is a dramatic blend of dance, music, Kalaripayattu and religious worship, usually dedicated to specific deities. It is also a reflection of religious harmony in these parts, where some of the deities  are Mappila Muslim heroes.
</p>
                    <p> Interestingly, Village Theyyam were one of the first to embrace the communist movement in Kerala, largely because its performers belonged to the poorest of the poor. Equally interesting in fact that Theyyam dance-drama, unlike other temple performances is not idol-centric. In Theyyam, the performer assumes temporary incarnate powers – he is good and master, the manifestation of the divine. In many ways, this folk art form provides an outlet of protest and opposition in the name of god. 
</p>
                    <p> There are nearly 450 types of Theyyam that are performed in the many kavus, or scared groves, that dot Kannur , Kasaragod districts of Kerala. Many families hold them in their ancestral houses to bring prosperity, ward off the evil eye and to prevent diseases. The best season to watch Theyyam performances in Kannur and Kasaragod is December to May.
</p>
                    <p>Shri Muthappan, Parassinikadavu
 </p>
                    <p>The parasinikadavu Temple, dedicated to the toddy and fried fish loving deity, Shri Muthappan, Sits picturesquely on the banks of the Valapattanam river. Muthappan is an incarnation of Maha Vishnu and also the son of Shiva and Parvathi.
 </p>
                    <p> About the toddy and fried fish connection, legend goes that a childless couple found and adopted an abandoned baby boy. As he grew up, he took to hunting and eating flesh, taboo to his parents. The father disappointed, wanted to kill himself. But the boy manifested himself in the form of Vishnu the hunter, and took the name of Muthappan. In another instance, the boy being refused toddy by a tapper, turned him in to stone and proceeded to paracchinga kavu (ghat of thorny plants) on the Valapattanam river. A low-caste family recognized his divinity and appeased him with toddy and fish. The ritual continues to this day and a member of this family still plays a role in the Theyyam preformed at the temple.</p>
                    <p> The morning performances are better both for ambience and fact that there are two performers then. Wake up early and don a sari if you are a woman , or a mundu if you are a man (no salwar kameezzes or trousers ) and drive through empty streets of parassinikadavu. 
</p>
                    <p>Parassinikadavu is 18 Km north of Kannur. Take national highway towards Kasaragod and 13 km later turn right at the signs announcing the way to the temple. Theyyam timings 5-8 am ,4-8 pm . Main festival Thiruvappana in January. </p>
                 
                  </div>

                </div>

                
                
              </div>

            </div>

          </div>

          
        </main>

      <Footer/>
    </React.Fragment>
  );
}

export default Theyyam;
