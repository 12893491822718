import React from 'react';
export default class PackageData extends React.Component {
    constructor() {
        super();
    }
    render() {
      return (
          <React.Fragment>
              {this.props.packageName == 'amazing-kerala' && 
                <div>
                    Day 1: Arrive - Kochi
                    <p>
                    Welcome to Kerala!! Upon your arrival at the Kochi airport, later proceed to a sightseeing tour of the oasis state of Kerala and one of the India’s most colourful cities. You will have a walk around the Synagogue. The area around the synagogue is known as “Jew town” and is one of the Kochi’ spice trade centres. We also stop at Mattancherry Dutch Palace which was built by the Portuguese, and presented to the Maharaja of Cochin in 1568. Proceeding ahead you will visit St. Francis Church; the first church to be built in India by the Portuguese. After visiting Santa Cruz Cathedral enjoy strolling on the beach side viewing the life at Chinese fishing nets. In the evening enjoy a Kathakali Dance Performance. Kathakali is a ritualized dance form that is native to Kerala, with elaborate costumes and makeup. Male dancers portray both male and female roles that tell the stories taken mostly from the epics of the Mahabharatha and Ramayana. Stay overnight at Cochin.
                    </p>
                    Day 2: Cochin – Munnar
                    <p>
                    Travel to Munnar, a place to enjoy scenic beauty and marvellous waterfalls, high mountains with the
                    low flying clouds passing by. The town stands at a confluence of three mountain streams –
                    Mudrapuzha, Nallathanni and Kundala. The three streams later join the Periyar river and finally the
                    Arabian Sea. famous for its tea gardens and summer resort of the erstwhile British administration in
                    South India. Stay two nights at hotel.
                    </p>
                    Day 3: Munnar
                    <p>
                    Enjoy leisurely breakfast, walk through the enchanting tea estates and enjoy the tranquillity it
                    provides while watching women’s plucking the tea leaves at the tea estates. It&#39;s the place you would
                    love to visit - it&#39;s the place you would wish never to leave. We’ll also visit Tea Processing Factory &amp;
                    Museum. The museum puts light on the important fact and figures about the growth of the tea
                    plantations in Munnar. It contains curios, old photographs, machineries and other things which have
                    been used since long time.
                    </p>
                    Day 4: Munnar – Thekkady
                    <p>
                    Morning travel to from tea estates to the valley of spices and see the spice plantation gardens.
                    Thekkady, with its bountiful treasures of tropical flora and fauna is the ultimate reservoir of many an
                    endangered species and a rich tribal culture. After a short rest proceed to view the rare species of
                    plantations, animals and visit a spice plantation growing cardamom, pepper, coffee, and tea estate.
                    Walk amidst the palette displaying variant shades of green, neatly arranged into terrace farms, the
                    symmetry broken only by the sudden appearance of a gurgling stream. It is believed that the spice
                    trade dates back three thousand years in Kerala and many spices find their home here. The slanting
                    slopes caress a variety of cinnamon, clove, nutmeg, ginger, turmeric and curry leaves. On the way
                    back you could stop at the local market and purchase some fragrant spices. Stay overnight at
                    Periyar. 
                    </p>
                    Day 5: Thekkady– Alleppey (TBA) Private Backwater Cruise
                    <p>
                    Morning drive to Alleppey, where you will board your private houseboat, converted from a
                    Kettuvallam or traditional rice barge, and sail through the beautiful backwaters of Kerala.
                    The backwaters in Kerala are a world of serenity and wonderment. This large aquatic area is a vast
                    network of lagoons, lakes, rivers and canals, and the people who live in this region have created
                    their own distinct lifestyles. Witness the peaceful way of life on these amazing waterways with
                    lunch, dinner and overnight stay on board; this will be a real highlight of your holiday. (B, L, D)
                    Day 6: Alleppey – Departure
                    Morning after breakfast checkout from the house boat. Tour finishes with wonderful memories and direct transfer to Cochin Airport to board onward flight.
                    </p>
                    Inclusions
                    <ul>
                        <li>Daily Continental Breakfast at all Hotels &amp; Resorts </li>
                        <li>Above mentioned exclusive star category accommodation on twin sharing basis. </li>
                        <li>Houseboat exclusively with all meals which includes welcome drink snacks tea lunch dinner</li>
                        breakfast mineral water etc. 
                        <li>The car at complete disposal of the passenger exclusively throughout the package for all
                            transfers and sightseeing. 
                        </li>
                        <li>Transportation as mentioned with an English/Hindi speaking friendly driver cum guide
                            throughout your trip. 
                        </li>
                        <li>Driver bata toll parking expenses night halt charges fuel and all applicable taxes included in
                            the cost.
                        </li>
                    </ul>
                    Exclusions
                    <ul>
                        <li>Anything other than inclusions.</li>
                    </ul>
                </div>
                }

            {this.props.packageName == 'exotic-kerala' && 
                <div>
                    Day 1: Arrive - Kochi
                    <p>
                    Welcome to Kerala!! Upon your arrival at the Kochi airport, later proceed to a sightseeing tour of the
                    oasis state of Kerala and one of the India’s most colourful cities. You will have a walk around the
                    Synagogue. The area around the synagogue is known as “Jew town” and is one of the Kochi’ spice
                    trade centres. We also stop at Mattancherry Dutch Palace which was built by the Portuguese, and
                    presented to the Maharaja of Cochin in 1568. Proceeding ahead you will visit St. Francis Church; the
                    first church to be built in India by the Portuguese. After visiting Santa Cruz Cathedral enjoy strolling
                    on the beach side viewing the life at Chinese fishing nets. In the evening enjoy a Kathakali Dance
                    Performance. Kathakali is a ritualized dance form that is native to Kerala, with elaborate costumes
                    and makeup. Male dancers portray both male and female roles that tell the stories taken mostly
                    from the epics of the Mahabharatha and Ramayana. Stay overnight at Cochin.
                    </p>
                    Day 2: Cochin – Munnar
                    <p>
                    Travel to Munnar, a place to enjoy scenic beauty and marvellous waterfalls, high mountains with the
                    low flying clouds passing by. The town stands at a confluence of three mountain streams –
                    Mudrapuzha, Nallathanni and Kundala. The three streams later join the Periyar river and finally the
                    Arabian Sea. famous for its tea gardens and summer resort of the erstwhile British administration in
                    South India. Stay two nights at hotel.
                    </p>
                    Day 3: Munnar
                    <p>
                    Enjoy leisurely breakfast, walk through the enchanting tea estates and enjoy the tranquillity it
                    provides while watching women’s plucking the tea leaves at the tea estates. It&#39;s the place you would
                    love to visit - it&#39;s the place you would wish never to leave. We’ll also visit Tea Processing Factory &amp;
                    Museum. The museum puts light on the important fact and figures about the growth of the tea
                    plantations in Munnar. It contains curios, old photographs, machineries and other things which have
                    been used since long time.
                    </p>
                    Day 4: Munnar – Thekkady
                    <p>
                    Morning travel to from tea estates to the valley of spices and see the spice plantation gardens.
                    Thekkady, with its bountiful treasures of tropical flora and fauna is the ultimate reservoir of many an
                    endangered species and a rich tribal culture. After a short rest proceed to view the rare species of
                    plantations, animals and visit a spice plantation growing cardamom, pepper, coffee, and tea estate.
                    Walk amidst the palette displaying variant shades of green, neatly arranged into terrace farms, the
                    symmetry broken only by the sudden appearance of a gurgling stream. It is believed that the spice
                    trade dates back three thousand years in Kerala and many spices find their home here. The slanting
                    slopes caress a variety of cinnamon, clove, nutmeg, ginger, turmeric and curry leaves. On the way
                    back you could stop at the local market and purchase some fragrant spices. Stay overnight at
                    Periyar. 
                    </p>
                    Day 5: Thekkady– Alleppey (TBA) Private Backwater Cruise
                    <p>
                    Morning drive to Alleppey, where you will board your private houseboat, converted from a
                    Kettuvallam or traditional rice barge, and sail through the beautiful backwaters of Kerala.
                    The backwaters in Kerala are a world of serenity and wonderment. This large aquatic area is a vast
                    network of lagoons, lakes, rivers and canals, and the people who live in this region have created
                    their own distinct lifestyles. Witness the peaceful way of life on these amazing waterways with
                    lunch, dinner and overnight stay on board; this will be a real highlight of your holiday. (B, L, D)
                    Day 6: Alleppey – Kovalam (by surface: 04 ½ hrs drive)
                    Morning disembark after breakfast and travel to Kovalam through green coastal belt and cashew
                    plantations. An enticing walk along the beach to enjoy the scene of the Arabian waves embracing
                    virgin sands, with steep palm covered headland. A glittering silvery sand contour into an arc, edging
                    on one side with cerulean waters and on the other side by a magnificent row of the green palm
                    groves, provides a spectacular view of the seeming eternal sea and sunsets. The waterfront is lined
                    with a number of shops with spreads of jewelry, handicrafts and clothes. Kovalam means a grove of
                    coconuts trees and truly the coconut trees along the beaches gives it a ravishing look. Stay two
                    nights at Kovalam. 
                    </p>
                    Day 7: Kovalam
                    <p>
                    After breakfast day is free at leisure to relax by the beach or just relax by the pool / enjoy the hotel
                    facilities. Afternoon relax and rejuvenate yourself with goodness of Ayurveda herbal oil massage.
                    Overnight stay in Kovalam. 
                    </p>
                    Day 8: Kovalam – Trivandrum &amp; departure
                    <p>
                    Morning after breakfast checkout from the hotel. Tour finishes with wonderful memories and direct
                    transfer to Trivandrum Airport to board onward flight.
                    </p>
                    Inclusions
                    
                    <ul>
                    
                    <li>Daily Continental Breakfast at all Hotels &amp; Resorts </li>
                    <li>Above mentioned exclusive star category accommodation on twin sharing basis. </li>
                    <li>Houseboat exclusively with all meals which includes welcome drink snacks tea lunch dinner</li>
                    breakfast mineral water etc. 
                    <li>The car at complete disposal of the passenger exclusively throughout the package for all
                    transfers and sightseeing. </li>
                    <li>Transportation as mentioned with an English/Hindi speaking friendly driver cum guide
                    throughout your trip. </li>
                    <li>Driver bata toll parking expenses night halt charges fuel and all applicable taxes included in
                    the cost.</li></ul>
                    Exclusions
                    <ul>
                    <li>Anything other than inclusions.</li>
                    </ul>
                </div>
                }
                {this.props.packageName == 'best-kerala' && 
                    <div>
                    Day 1: Arrive - Kochi -Munnar
                    <p>
                    Welcome to Kerala!! Upon your arrival at the Kochi airport, later proceed to Munnar, a place to enjoy scenic beauty and marvellous waterfalls, high mountains with the low flying clouds passing by. The town stands at a confluence of three mountain streams – Mudrapuzha, Nallathanni and Kundala. The three streams later join the Periyar river and finally the Arabian Sea.  famous for its tea gardens and summer resort of the erstwhile British administration in South India. Stay two nights at hotel.
                    </p>
                    Day 2: Munnar
                    <p>
                    Enjoy leisurely breakfast, walk through the enchanting tea estates and enjoy the tranquillity it provides while watching women’s plucking the tea leaves at the tea estates. It's the place you would love to visit - it's the place you would wish never to leave. We’ll also visit Tea Processing Factory & Museum. The museum puts light on the important fact and figures about the growth of the tea plantations in Munnar. It contains curios, old photographs, machineries and other things which have been used since long time.
                    </p>
                    Day 3: Munnar – Thekkady 
                    <p>
                    Morning travel to from tea estates to the valley of spices and see the spice plantation gardens. Thekkady, with its bountiful treasures of tropical flora and fauna is the ultimate reservoir of many an endangered species and a rich tribal culture. After a short rest proceed to view the rare species of plantations, animals and visit a spice plantation growing cardamom, pepper, coffee, and tea estate. Walk amidst the palette displaying variant shades of green, neatly arranged into terrace farms, the symmetry broken only by the sudden appearance of a gurgling stream. It is believed that the spice trade dates back three thousand years in Kerala and many spices find their home here. The slanting slopes caress a variety of cinnamon, clove, nutmeg, ginger, turmeric and curry leaves. On the way back you could stop at the local market and purchase some fragrant spices. Stay overnight at Periyar. 
                    </p>
                    Day 4: Thekkady– Alleppey (TBA) Private Backwater Cruise
                    <p>
                    Morning drive to Alleppey, where you will board your private houseboat, converted from a Kettuvallam or traditional rice barge, and sail through the beautiful backwaters of Kerala. The backwaters in Kerala are a world of serenity and wonderment. This large aquatic area is a vast network of lagoons, lakes, rivers and canals, and the people who live in this region have created their own distinct lifestyles. Witness the peaceful way of life on these amazing waterways with lunch, dinner and overnight stay on board; this will be a real highlight of your holiday. (B, L, D)
                    </p>
                    Day 5: Alleppey – Kovalam (by surface: 04 ½ hrs drive) 
                    <p>
                    Morning disembark after breakfast and travel to Kovalam through green coastal belt and cashew plantations. An enticing walk along the beach to enjoy the scene of the Arabian waves embracing virgin sands, with steep palm covered headland. A glittering silvery sand contour into an arc, edging on one side with cerulean waters and on the other side by a magnificent row of the green palm groves, provides a spectacular view of the seeming eternal sea and sunsets. The waterfront is lined with a number of shops with spreads of jewelry, handicrafts and clothes. Kovalam means a grove of coconuts trees and truly the coconut trees along the beaches gives it a ravishing look. Stay two nights at Kovalam
                    </p>
                    Day 6: Kovalam 
                    <p>
                    After breakfast day is free at leisure to relax by the beach or just relax by the pool / enjoy the hotel facilities. Afternoon relax and rejuvenate yourself with goodness of Ayurveda herbal oil massage. Overnight stay in Kovalam.   
                    </p>
                    Day 7: Kovalam – Trivandrum & departur
                    <p>
                    Morning after breakfast checkout from the hotel. Tour finishes with wonderful memories and direct transfer to Trivandrum Airport to board onward flight.
                    </p>
                    
                    Inclusions
                    
                    <ul>
                    
                    <li>Daily Continental Breakfast at all Hotels &amp; Resorts </li>
                    <li>Above mentioned exclusive star category accommodation on twin sharing basis. </li>
                    <li>Houseboat exclusively with all meals which includes welcome drink snacks tea lunch dinner</li>
                    breakfast mineral water etc. 
                    <li>The car at complete disposal of the passenger exclusively throughout the package for all
                    transfers and sightseeing. </li>
                    <li>Transportation as mentioned with an English/Hindi speaking friendly driver cum guide
                    throughout your trip. </li>
                    <li>Driver bata toll parking expenses night halt charges fuel and all applicable taxes included in
                    the cost.</li></ul>
                    Exclusions
                    <ul>
                    <li>Anything other than inclusions.</li>
                    </ul>
                    </div>
                }

                {this.props.packageName == 'kerala-backwater' && 
                    <div>
                    Day 1: Arrive - Kochi -Kumarakom
                    <p>
                    Welcome to Kerala!! Upon your arrival at the Kochi airport, later proceed to Kumarakom; an unbelievably beautiful paradise of mangrove forests, emerald green paddy fields and coconut groves interspersed with enchanting waterways and canals adorned with white lilies. Laze in a canoe amidst sylvan surroundings where the only presence is yours and the egrets. Later visit the famous Kumarakom Bird Sanctuary that gives you the pleasure to meet a variety of migratory birds that cluster in thousands some of the Himalayas and some even from the snow-kissed hills of Siberia, a true multicultural avian metropolis. Watch numerous alluring species of birds, including Darters, Bitterns, Brahminy kites, Marsh Harries, Teals and several varieties of Herons roost in their nests on the green acme, chirping and taking flight every now and then. For the inquisitive bird watcher, it is always advisable to carry binoculars at all times. Dinner and overnight at Kumarakom.
                    </p>
                    Day 2: Kumarakom-Alleppey
                    <p>
                    Morning drive to Alleppey, where you will board your private houseboat, converted from a Kettuvallam or traditional rice barge, and sail through the beautiful backwaters of Kerala. The backwaters in Kerala are a world of serenity and wonderment. This large aquatic area is a vast network of lagoons, lakes, rivers and canals, and the people who live in this region have created their own distinct lifestyles. Witness the peaceful way of life on these amazing waterways with lunch, dinner and overnight stay on board; this will be a real highlight of your holiday. Your journey will start from Pallathurathy starting at 12 noon cruise towards Kannjipadam - The land situated in a picturesque surrounding with- all beauty of Kerala - Coconut palm plantations, paddy fields, backwaters & canals. After lunch at Kannjipadam cruise via Thottapally towards- Thakazhi by reaching by 5.30PM. We will anchor the house boat and then after proceed for a village walks. The land is bounded by backwater and is the birth place of famous Malyalam writer Thakazhi Sivasankara Pillai. Thakazhy was a centre of Buddhism during the early days. It was under the Chempakassery Kingdom, which is annexed to Travancore by Marthanda Varma. After you finish exploring the village return to house boat for dinner and overnight stay.   (B, L, D)
                    </p>
                    Day 3: Alleppey –Departure
                    <p>
                    Morning after breakfast checkout from the house boat. Tour finishes with wonderful memories and direct transfer to Cochin Airport to board onward flight.
                    </p>
                    
                    Inclusions
                    
                    <ul>
                    
                    <li>Daily Continental Breakfast at all Hotels &amp; Resorts </li>
                    <li>Above mentioned exclusive star category accommodation on twin sharing basis. </li>
                    <li>Houseboat exclusively with all meals which includes welcome drink snacks tea lunch dinner</li>
                    breakfast mineral water etc. 
                    <li>The car at complete disposal of the passenger exclusively throughout the package for all
                    transfers and sightseeing. </li>
                    <li>Transportation as mentioned with an English/Hindi speaking friendly driver cum guide
                    throughout your trip. </li>
                    <li>Driver bata toll parking expenses night halt charges fuel and all applicable taxes included in
                    the cost.</li></ul>
                    Exclusions
                    <ul>
                    <li>Anything other than inclusions.</li>
                    </ul>
                    </div>
                }
                {this.props.packageName == 'moemorising-munnar' && 
                    <div>
                    Day 1: Arrive - Kochi -Munnar
                    <p>
                    Welcome to Kerala!! Upon your arrival at the Kochi airport, later proceed  to Munnar, a place to enjoy scenic beauty and marvellous waterfalls, high mountains with the low flying clouds passing by. The town stands at a confluence of three mountain streams – Mudrapuzha, Nallathanni and Kundala. The three streams later join the Periyar river and finally the Arabian Sea.  famous for its tea gardens and summer resort of the erstwhile British administration in South India. Stay two nights at hotel.
                    </p>
                    Day 2: Munnar
                    <p>
                    Enjoy leisurely breakfast, walk through the enchanting tea estates and enjoy the tranquillity it provides while watching women’s plucking the tea leaves at the tea estates. It's the place you would love to visit - it's the place you would wish never to leave. We’ll also visit Tea Processing Factory & Museum. The museum puts light on the important fact and figures about the growth of the tea plantations in Munnar. It contains curios, old photographs, machineries and other things which have been used since long time.
                    </p>
                    Day 3: Munnar –Departure
                    <p>
                    Morning after breakfast checkout from the hotel. Tour finishes with wonderful memories and direct transfer to Cochin Airport to board onward flight.
                    </p>
                    
                    Inclusions
                    
                    <ul>
                    
                    <li>Daily Continental Breakfast at all Hotels &amp; Resorts </li>
                    <li>Above mentioned exclusive star category accommodation on twin sharing basis. </li>
                    <li>Houseboat exclusively with all meals which includes welcome drink snacks tea lunch dinner</li>
                    breakfast mineral water etc. 
                    <li>The car at complete disposal of the passenger exclusively throughout the package for all
                    transfers and sightseeing. </li>
                    <li>Transportation as mentioned with an English/Hindi speaking friendly driver cum guide
                    throughout your trip. </li>
                    <li>Driver bata toll parking expenses night halt charges fuel and all applicable taxes included in
                    the cost.</li></ul>
                    Exclusions
                    <ul>
                    <li>Anything other than inclusions.</li>
                    </ul>
                    </div>
                }
                {this.props.packageName == 'simple-kerala' && 
                    <div>
                    Day 1: Arrive - Kochi -Munnar
                    <p>
                    Welcome to Kerala!! Upon your arrival at the Kochi airport, later proceed  to Munnar, a place to enjoy scenic beauty and marvellous waterfalls, high mountains with the low flying clouds passing by. The town stands at a confluence of three mountain streams – Mudrapuzha, Nallathanni and Kundala. The three streams later join the Periyar river and finally the Arabian Sea.  famous for its tea gardens and summer resort of the erstwhile British administration in South India. Stay two nights at hotel.
                    </p>
                    Day 2: Munnar
                    <p>
                    Enjoy leisurely breakfast, walk through the enchanting tea estates and enjoy the tranquillity it provides while watching women’s plucking the tea leaves at the tea estates. It's the place you would love to visit - it's the place you would wish never to leave. We’ll also visit Tea Processing Factory & Museum. The museum puts light on the important fact and figures about the growth of the tea plantations in Munnar. It contains curios, old photographs, machineries and other things which have been used since long time.
                    </p>
                    Day 3: Munnar – Thekkady 
                    <p>
                    Morning travel to from tea estates to the valley of spices and see the spice plantation gardens. Thekkady, with its bountiful treasures of tropical flora and fauna is the ultimate reservoir of many an endangered species and a rich tribal culture. After a short rest proceed to view the rare species of plantations, animals and visit a spice plantation growing cardamom, pepper, coffee, and tea estate. Walk amidst the palette displaying variant shades of green, neatly arranged into terrace farms, the symmetry broken only by the sudden appearance of a gurgling stream. It is believed that the spice trade dates back three thousand years in Kerala and many spices find their home here. The slanting slopes caress a variety of cinnamon, clove, nutmeg, ginger, turmeric and curry leaves. On the way back you could stop at the local market and purchase some fragrant spices. Stay overnight at Periyar. 
                    </p>
                    Day 4: Thekkady– Alleppey (TBA) Private Backwater Cruise
                    <p>
                    Morning drive to Alleppey, where you will board your private houseboat, converted from a Kettuvallam or traditional rice barge, and sail through the beautiful backwaters of Kerala. The backwaters in Kerala are a world of serenity and wonderment. This large aquatic area is a vast network of lagoons, lakes, rivers and canals, and the people who live in this region have created their own distinct lifestyles. Witness the peaceful way of life on these amazing waterways with lunch, dinner and overnight stay on board; this will be a real highlight of your holiday. (B, L, D)
                    </p>
                    Day 5: Alleppey – Departure
                    <p>
                    Morning after breakfast checkout from the house boat. Tour finishes with wonderful memories and direct transfer to Cochin Airport to board onward flight.
                    </p>
                    
                    Inclusions
                    
                    <ul>
                    
                    <li>Daily Continental Breakfast at all Hotels &amp; Resorts </li>
                    <li>Above mentioned exclusive star category accommodation on twin sharing basis. </li>
                    <li>Houseboat exclusively with all meals which includes welcome drink snacks tea lunch dinner</li>
                    breakfast mineral water etc. 
                    <li>The car at complete disposal of the passenger exclusively throughout the package for all
                    transfers and sightseeing. </li>
                    <li>Transportation as mentioned with an English/Hindi speaking friendly driver cum guide
                    throughout your trip. </li>
                    <li>Driver bata toll parking expenses night halt charges fuel and all applicable taxes included in
                    the cost.</li></ul>
                    Exclusions
                    <ul>
                    <li>Anything other than inclusions.</li>
                    </ul>
                    </div>
                }

{this.props.packageName == 'wayanad-wild' && 
                    <div>
                    Day 1: Arrive - Wayanad
                    <p>
                    Welcome to Kerala!! Upon your arrival at the Calicut/Kannur airport, later proceed  to Wayanad, you will arrive to exotic legends, ancient ruins, mysterious mountain caves, aborigine tribes, hidden treasures, tree houses, jungle trails and exotic wild life, the green paradise is nestled among the mountains of the Western Ghats, forming the border world of the greener part of Kerala. Clean and pristine, enchanting and hypnotizing, this land is filled with history and culture. Located at a distance of 76 km. from the sea shores of Kozhikode, this verdant hill station is full of plantations, forests and wildlife. Later check in at resort and walk around the resort and stay for two nights in Wayanad. 
                    </p>
                    Day 2: Wayanad
                    <p>
                    Morning get ready for a fun packed jungle safari. A jungle safari along the Wayanad Wildlife Sanctuary where elephants, deer, sloth bears, tigers and panthers roam free and numerous rare birds chirp through the skies. Set off towards the Kuruwa Island, a bunch of desolate islets sprinkled in the middle of the vast Kabini River. Now it is a soaring hub of ecotourism supported by the locals as well as forest administration. Crossing the dangerous-looking and rock-strewn waters is an experience to cherish. Also, check out some tracks off the beaten track with the help of your guide.  On the way back, visit Edakkal Caves too. Afternoon free to relax at the resort.
                    </p>
                    Day 3: Wayanad – Departure
                    <p>
                    Morning after breakfast checkout from the hotel. Tour finishes with wonderful memories and direct transfer to Calicut/Kannur Airport to board onward flight.
                    </p>
                   
                    
                    Inclusions
                    
                    <ul>
                    
                    <li>Daily Continental Breakfast at all Hotels &amp; Resorts </li>
                    <li>Above mentioned exclusive star category accommodation on twin sharing basis. </li>
                    <li>Houseboat exclusively with all meals which includes welcome drink snacks tea lunch dinner</li>
                    breakfast mineral water etc. 
                    <li>The car at complete disposal of the passenger exclusively throughout the package for all
                    transfers and sightseeing. </li>
                    <li>Transportation as mentioned with an English/Hindi speaking friendly driver cum guide
                    throughout your trip. </li>
                    <li>Driver bata toll parking expenses night halt charges fuel and all applicable taxes included in
                    the cost.</li></ul>
                    Exclusions
                    <ul>
                    <li>Anything other than inclusions.</li>
                    </ul>
                    </div>
                }

        </React.Fragment>
        );
    }
}